import PropTypes from "prop-types"
import React from "react"
import { 
  Button,
  InputLabel,
  MenuItem,
  Select
 } from "@material-ui/core";
import { useAuth } from "react-use-auth";
import AGICustomerJourneyLogo from '../../assets/AGI_Customer_Journey_Logo.png'
import './index.scss'

const scrollTop = (event) => {
  event.preventDefault();
}

const Login = ({journeyName, onChange, userHasAccess}) => {
 const { isAuthenticated, logout } = useAuth()
  if (isAuthenticated()) {
    if (userHasAccess && userHasAccess() === true) {
      return (
        <>
          <InputLabel htmlFor="select-journey">Select Journey:</InputLabel>
          <Select
            className="select"
            value={journeyName}
            onOpen={scrollTop}
            onChange={onChange}
            name="journey"
            MenuProps={{
              className: "selectMenu",
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              }
            }}
          >
            <MenuItem value={'Producer Equipment Purchase'}>Producer Equipment Purchase</MenuItem>
            <MenuItem value={'Producer Data Management'}>Producer Data Management</MenuItem>
          </Select>
        <Button 
          class="logout-button"
          onClick={logout}
          variant="outlined"
          >
            LOGOUT
        </Button>
      </>
      )
    }
  } 
  return(
    <></>
  )
}

const Header = ({ siteTitle, onChange, journeyName, isAuthenticated, userHasAccess }) => (
  <header className='header'>
    <div className='header-container'>
      <span className="left">
        <img src={AGICustomerJourneyLogo} alt='AGI Customer Journey' height='30px' />
      </span>
      <span className="right">
        <Login onChange={onChange} journeyName={journeyName} isAuthenticated={isAuthenticated} userHasAccess={userHasAccess} />
      </span>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
