import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import '../../styles/main.scss';
import './index.scss';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ journeyName, setJourney, isAuthenticated, userHasAccess, children, setTabs }) => {
  const handleChange = (event) => {
      const selected = event.target.value;
      const defaultPhase = selected === 'Producer Equipment Purchase' ? 'Research & Awareness' : 'Consideration of New Data Tools';
      const defaultStep = selected === 'Producer Equipment Purchase' ? 'Farm Planning' : 'Discovery and Research';
      setJourney({journeyName: selected});
      setTabs({phaseTab: defaultPhase, stepTab: defaultStep});
  };

  return (
    <div className='outer-container' id='outer'>
      <Header siteTitle={'AGI Dashboard'} onChange={handleChange} journeyName={journeyName} isAuthenticated={isAuthenticated} userHasAccess={userHasAccess} />
      <div className='body-container'>
        <main className='middle-container'>
          {children}
        </main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout